import React from 'react';
import './App.css';
import SignIn from './component/singin';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      </header>
      <SignIn></SignIn>
    </div>
  );
}

export default App;
